import CONST from '@/utils/Constants'
import ApiHelper from "@/helpers/ApiHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
import CardTitle from "@/components/CardTitle.vue";
import StartVideoPlayer from "@/components/StartVideoPlayer.vue";
import RoundStartButton from "@/components/RoundStartButton.vue";
import oButton from '@/objects/o-button/o-button.vue';
import { mapState } from "vuex";

export default {
  name: "start",

  components: {
    CardTitle,
    StartVideoPlayer,
    'o-button': oButton,
    RoundStartButton
  },

  computed: {
    ...mapState(["uploaded_video_url"]),
    content() {
      return this.$store.getters.content;
    },
    videoIsUploaded() {
      return this.$store.state.video.uploaded;
    }, 
    shareVideo() {
      if(this.videoIsUploaded) {
        this.$router.push({ name: 'share-video'});
      }
    },
    shareVideoIcon() {
      if(this.videoIsUploaded) {
        return 'cloud_download';
      }else{
        return 'preloader';
      }
    }
  },

  data: () => ({
    video_src : ''
  }),

  methods: {
    change_video() {
      if (this.video_src != this.$route.params.video_src) {
        this.video_src = this.$route.params.video_src;
      }
      this.showPlayButton();
    },
    play() {
      this.$refs.my_vlog.play();
      this.$refs.start_button.style.display = "none";
    },
    showPlayButton() {
      this.$refs.start_button.style.display = "block";
    }
  },

  mounted() {
    this.video_src = this.content.result_video;

    setTimeout(() => {
      this.play()
    }, 200);

    let data = {
      "message": this.getEmsDataPropertyText('global_background_image'),
      "channel": CONST.PUSHER.CHANNEL,
      "event-name": CONST.EVENT.BACKGROUND.SELECTED,
    }

    ApiHelper.post(CONST.API.BACKGROUND.SELECT, data, {headersType: 'basic'})
    .catch(error => {
        ErrorHelper.catch(error, this);
    });
  },
  
  beforeDestroy() {
    if (this.$route.params.video_src) {
      window.URL.revokeObjectURL(this.$route.params.video_src);
    }
  }
};