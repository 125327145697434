<template>
  <button class="start-button">
    <slot>
      <img src="../assets/img/play.png" height="90px" />
    </slot>
  </button>
</template>
<script>

export default {
  name: "RoundStartButton"
};

</script>

<style scoped>

.start-button {
  height: 10rem;
  width: 10rem;
  background-color: orangered;
  border-radius: 5rem;
  z-index: 1;
  top: 0;
  position: absolute;
  margin: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  padding-left: 50px;
  padding-top: 30px;
  border: 10px solid;
  border-color: white;
}

</style>