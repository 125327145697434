<template>
  <div class="video-player">
    <slot>No content</slot>
  </div>
</template>

<script>

export default {
  name: "StartVideoPlayer"
};

</script>

<style scoped>

video {
  width: 888px;
  height:auto;
  display: block;
}

.video-player {
  padding: 25px;
  background-color: #002f66;
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  border-radius: 25px;
}

</style>